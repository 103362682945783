import React from 'react';
import classes from './Pro1.module.css';
import bbd from './../../Assets/prop1.png';

import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';



import AK2350 from './../Products/Pdf/ak2350.pdf';
import AK3350 from './../Products/Pdf/ak3350.pdf';
import AK5150 from './../Products/Pdf/ak5150.pdf';
import AK5360 from './../Products/Pdf/ak5360.pdf';
import AK6350 from './../Products/Pdf/ak6350.pdf';
import AK8350 from './../Products/Pdf/ak8350.pdf';
import AK8350H from './../Products/Pdf/ak8350h.pdf';
import AK10350 from './../Products/Pdf/ak10350.pdf';
import AK15350 from './../Products/Pdf/ak15350.pdf';
import AK35350 from './../Products/Pdf/ak35350.pdf';
import AKS from './../Products/Pdf/aksmallseries.pdf';





const Pro1 = () => {
  return (
    <div className={classes.Pro1}>

<div className={classes.Prw}>
        <div className={classes.l1}><Fade left>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

        <Fade right>
        <div className={classes.r1}>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> DEWATERING<span className={classes.heads1}> PUMPS</span>
        </h4>
       
       
        <div className={classes.wrapper}>
 
  
  <table className={classes.keywords} cellspacing="5" cellpadding="0">
    <thead>
      <tr>
        <th>PRODUCT</th>
        <th>VIEW</th>
        <th>VIEW PDF</th>
    
      
      </tr>
    </thead>
    <tbody>
    <tr>
        <td className={classes.lalign}>AK-2350-2P</td>
        <td><Link to='/AK2350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK2350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       
         </tr>
      <tr>
        <td className={classes.lalign}>AK-3350-2P</td>
        <td><Link to='/AK3350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK3350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
        
      </tr>
      <tr>
        <td className={classes.lalign}>AK-1-5150-2P</td>
        <td><Link to='/AK5150' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK5150}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
      
   </tr>

      <tr>
        <td className={classes.lalign}>AK-1-5350-2P</td>
        <td><Link to='/AK5350' className={classes.bbx}>VIEW</Link></td>
     {/*<td><a className={classes.bbx} href={AK5350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
        */}
      </tr>

      <tr>
        <td className={classes.lalign}>AK-1-5360-2P</td>
        <td><Link to='/AK5360' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK5360}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       

      </tr>
    
      <tr>
        <td className={classes.lalign}>AK-6350-2P</td>
        <td><Link to='/AK6350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK6350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       

      </tr>
      <tr>
        <td className={classes.lalign}>AK-8350-2P</td>
        <td><Link to='/AK8350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK8350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
        
   
      </tr>
      <tr>
        <td className={classes.lalign}>AK-8350H-2P</td>
        <td><Link to='/AK8350H' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK8350H}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       
      </tr>
      <tr>
        <td className={classes.lalign}>AK-10350-2P</td>
        <td><Link to='/AK10350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK10350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       
   
      </tr>
      <tr>
        <td className={classes.lalign}>AK-15350-2P</td>
        <td><Link to='/AK15350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK15350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       
      </tr>
      <tr>
        <td className={classes.lalign}>AK-35350M-L-2P</td>
        <td><Link to='/AK35350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AK35350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       
      </tr>
      <tr>
        <td className={classes.lalign}>AK-SMALL-SERIES</td>
        <td><Link to='/AKs' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={AKS}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
       
      </tr>
  

    </tbody>
  </table>
 </div> 
 </center>
        </div>
        </Fade>

</div>

    </div>
  )
}

export default Pro1