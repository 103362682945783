import React from 'react';
import Nav from './../Component/Nav/Nav';
import Foot from './../Component/Footer/Footer';
import Galler from './../Component/Infrastructure/Gallery';
import { useEffect } from "react";

const Gallery = () => {

  useEffect(()=>{
    window.scrollTo(0,0);


// Set meta title dynamically
document.title = 'Best Pumps Manufacturer in Pune | KPR’s Dewatering, Non-clog, Sewage pumps manufacturer, Supplier and Exporter in Pune. ';

// Set meta description dynamically
const metaDescription = document.createElement('meta');
metaDescription.name = 'description';
metaDescription.content = 'KPR’s is the Leading manufacturer of efficient dewatering, non-clog, and sewage pumps. Your trusted supplier and exporter for reliable wastewater solutions';
document.head.appendChild(metaDescription);

// Set meta keywords dynamically
const metaKeywords = document.createElement('meta');
metaKeywords.name = 'keywords';
metaKeywords.content = 'Submersible Dewatering Pump Manufacturer |Electric driven Submersible Dewatering Pump Manufacturer in Pune |Sump Pump in Pune |Drainage Pump Manufacturer |Tank Cleaning Pump Manufacturing company in Maharashtra |Submersible Mud Pump In Maharashtra | Submersible Mud Pump In Pune |Submersible Slurry Pump |Submersible Non-clog Pump Supplier | Residential & Industrial -Steel, Power Refineries | Waste Management solutions in Pune | Industrial Waste treatment plants.'; // Add your keywords here
document.head.appendChild(metaKeywords);


const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'Best Pumps Manufacturer in Pune | KPR’s Dewatering, Non-clog, Sewage pumps manufacturer, Supplier and Exporter in Pune.  ';
    document.head.appendChild(ogTitle);

    // Set Open Graph description dynamically
    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'KPR’s is the Leading manufacturer of efficient dewatering, non-clog, and sewage pumps. Your trusted supplier and exporter for reliable wastewater solutions';
    document.head.appendChild(ogDescription);

    // Set Open Graph keywords dynamically
    const ogKeywords = document.createElement('meta');
    ogKeywords.property = 'og:keywords';
    ogKeywords.content = 'Submersible Dewatering Pump Manufacturer |Electric driven Submersible Dewatering Pump Manufacturer in Pune |Sump Pump in Pune |Drainage Pump Manufacturer |Tank Cleaning Pump Manufacturing company in Maharashtra |Submersible Mud Pump In Maharashtra | Submersible Mud Pump In Pune |Submersible Slurry Pump |Submersible Non-clog Pump Supplier | Residential & Industrial -Steel, Power Refineries | Waste Management solutions in Pune | Industrial Waste treatment plants.'; // Add your keywords here
    document.head.appendChild(ogKeywords);


// Cleanup on component unmount
return () => {
  document.title = 'Default Title'; // Reset the title if needed
  document.head.removeChild(metaDescription);
  document.head.removeChild(metaKeywords);
  document.head.removeChild(ogTitle);
  document.head.removeChild(ogDescription);
  document.head.removeChild(ogKeywords);
};



},[])


  return (
    <div>

<Galler/>
<Foot></Foot>


    </div>
  )
};

export default Gallery