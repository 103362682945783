import React from 'react';
import classes from './Pro2.module.css';
import bbd from './../../Assets/prop4.png';
import { Link } from 'react-router-dom';

import SK3350 from './../Products/Pdf/sk3350.pdf';
import SK6350 from './../Products/Pdf/sk6350.pdf';
import SK8350 from './../Products/Pdf/sk8350.pdf';
import SK10350 from './../Products/Pdf/sk10350.pdf';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Pro1 = () => {
  return (
    <div className={classes.Pro2}>

<div className={classes.Prw}>
    


        <div className={classes.l1}><Fade left>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> NON-CLOG<span className={classes.heads1}> PUMPS</span>
        </h4>
       
       
        <div className={classes.wrapper}>
 
  
  <table className={classes.keywords} cellspacing="0" cellpadding="0">
    <thead>
      <tr>
        <th>PRODUCT</th>
        <th>VIEW</th>
        <th>DOWNLOAD</th>
      
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className={classes.lalign}>SK 3350 NC</td>
        <td><Link to='/SK3350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={SK3350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>

      </tr>
      <tr>
        <td className={classes.lalign}>SK 6350 NC</td>
        <td><Link to='/SK6350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={SK6350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>

      </tr>
      <tr>
        <td className={classes.lalign}>SK 8350 NC</td>
        <td><Link to='/SK8350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={SK8350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>

      </tr>
      <tr>
        <td className={classes.lalign}>SK 10350 NC</td>
        <td><Link to='/SK10350' className={classes.bbx}>VIEW</Link></td>
        <td><a className={classes.bbx} href={SK10350}  target="_blank" rel="noreferrer">VIEW PDF</a></td>
  
      </tr>

    </tbody>
  </table>
 </div>





 </center></Fade>
        </div>

        <div className={classes.r1}><Fade right>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

</div>
    </div>
  )
}

export default Pro1