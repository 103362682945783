import React from 'react';
import classes from './Qual.module.css';

const Qual = () => {
  return (
    <div className={classes.qa}>
<div className={classes.aq}>  

<center>
    
<h3 className={classes.head}>QUALITY <span className={classes.heads}>ASSURANCE</span></h3>

<p className={classes.p}>We introduce the ISO-certified quality management system (QMS). The materials and components are thoroughly inspected, and the finished goods are tested in accordance with business and ISO standards. All of these controls over the various procedures are observed in accordance with the Quality Management System, which is based on requirements.</p>
</center>

</div>



    </div>
  )
}

export default Qual