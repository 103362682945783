import React from "react";
import Slider from "react-slick";
import Rcard from "./Rcard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "./Rsli.module.css";
import girl from './../../Assets/girl.png';
import man from './../../Assets/man.png';

const Rsli = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 769,
              settings: {
                speed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false  
              }
            }
        ]
    };

    return (
      
<div className={classes.rsl}>
    
                <Slider {...settings}>
<div>
<Rcard imgg={man} name='VIKAS DHAWADE' review='This pump manufacturer consistently delivers top-notch products with a reputation for durability and efficiency, making them a reliable choice for a wide range of industrial applications' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='ANIL APATE' review='Their commitment to innovation and customer support ensures that customers receive cutting-edge pumping solutions that not only meet their current needs but also adapt to future demands.' ></Rcard>
</div>
<div>
<Rcard imgg={girl} name='APURVA KANHETKAR' review='With a diverse product portfolio, competitive pricing, and a strong track record of performance, this pump manufacturer stands out as a go-to option for businesses seeking dependable fluid handling solutions.' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='PRATHEMSH DESHPANDE' review='I have found this pump manufacturer to be a reliable partner in our operations, providing us with efficient and durable pumping solutions.' ></Rcard>
</div>
<div>
<Rcard imgg={girl} name='RUJUTA NEMAL' review='This pump manufacturer consistently delivers top-notch products, combining innovative design with unmatched reliability to meet a wide range of industrial needs. ' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='KANALP RETDE' review='When it comes to pumping solutions, this manufacturer stands out for its technological advancements, offering a comprehensive range of pumps tailored to various industries. ' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='SHRIRAM KALUKE' review='Very Good Service, Pump manufacturing company' ></Rcard>
</div>
<div>
<Rcard imgg={girl} name='SHARWARI KOLHE' review='Having good Qualty Pumps' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='SHANKAR PATIL' review='GOOD SERVICE' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='RAJESH INDORE' review='NICE PUMP...' ></Rcard>
</div>
<div>
<Rcard imgg={man} name='SACHIN WANJALE' review='GOOD PUMP...' ></Rcard>
</div>


                </Slider>
       </div>     
       
    );
}

export default Rsli;
