import React from 'react';
import classes from './Fac2.module.css';

import img from './../../Assets/trasportation.jpg';
import img2 from './../../Assets/t1.jpg';
import img3 from './../../Assets/i4.JPG';
import img4 from './../../Assets/prop2.png';
import img5 from './../../Assets/prop1.png';
import vdo from './../../Assets/quatar.jpg';

import ni from './../../Assets/ni.jpg';
import Fade from 'react-reveal/Fade';

const Fac2 = () => {
  return (
    <div className={classes.hot}>

<section>

<div className={classes.container}>
<div className={classes.right}>
<Fade left>         <img src={img} alt="KPR'S PUMPS" className={classes.ri}/>    </Fade>
        </div>

        <div className={classes.left}>
        <Fade top>
          <h2 className={classes.heading}>TRANSPO<span className={classes.heads}>RTATION</span></h2><br></br></Fade>
          <Fade right>   <p className={classes.leftp}>&emsp;&emsp;&emsp; KPR'S PUMP offers the ability to transfer people on demand anywhere in the world. 60% of KPR's Pumps Company's goods are exported to GCC Countries, and the company provided the pumps for a number of high-profile projects there through reputable construction firms. The pumps are operating effectively in these countries. We have exporting specification.
</p></Fade>
        </div>

        <div className={classes.middle}>


<Fade top><img src={ni} alt="KPR'S PUMPS" className={classes.riii}/></Fade>
<Fade bottom><img src={img3} alt="KPR'S PUMPS" className={classes.riii}/></Fade>

</div>  
        
    </div>

    <br></br>   <br></br>
    <div className={classes.containe}>
 
   
    <Fade left><img src={img4} alt="KPR'S PUMPS" className={classes.rd}/> </Fade> 

    <Fade bottom> 
<div>
    <img src={vdo}  className={classes.rihi} alt="KPR'S PUMPS"/>
  <p className={classes.leftpa}>TRANSPORTED TO QATAR</p> 
</div> </Fade> 

<Fade right> <img src={img5} alt="KPR'S PUMPS" className={classes.rd}/></Fade>

 

    </div>

    </section>

    </div>
  )
}

export default Fac2