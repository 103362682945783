import classes from "./Commit.module.css";
import React from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Commit = () => {
  return (
    <div>

<div className={classes.commit}>
<center>
<Fade left>
<h1 className={classes.h}>

KPR'S <span className={classes.hh}>COMMITMENTS</span> .<span className={classes.hh}>.</span>.<span className={classes.hh}>.</span>

</h1>
</Fade>


<Zoom>
<p className={classes.p}>

Quality And Reliability In All Our Products, Strong Service And Support After The Sale, Lower Total Life<br/> Cycle Equipment Costs, Maximum Value For Your Investment.


</p>
</Zoom>
</center>
</div>

    </div>
  )
}

export default Commit