import React from 'react';
import Serpo from './Sepro';

import logo1 from './../../Assets/p4.png';
import logo2 from './../Products/img/ak5150.jpg';

const Ak5150m = () => {
  return (
    <div>
<Serpo 
h="Electric Submersible Dewatering Pumps"
sh="MODEL NO :  ( AK-1-5150-2P )"
img1={logo1} 
img2={logo2} 

ih="About  AK-1-5150-2P "
ip="KPR'S dewatering submersible pumps, known for job site dependability. Pumps are constructed of strong die cast aluminum and stainless steel body parts they are fitted with stainless steel hardened impellers and field adjustable nitrile rubber diffuser The both upper and lower mechanical seals are wholly enclosed in oil chamber These tough pumps do not have to be totally submerged and will operate under snore conditions if necessary. " 


ms="Double seals of Inner lip seal & Outer Tungsten Carbide Vs Tungsten Carbide and are wholly enclosed in oil chamber."

f1="1.5HP (1.1KW)/1Ø/50Hz/215Volt, 2800 RPM DOL
Start squirrel cage continuous rated induction
motor, insulation Class H (1800C)."

f2="9 Amps @ 215 Volt/1Ø/50Hz Ac Supply"

f3="Max.15 per hour"

f4="2” Hose type."


f5="15 meter, Water proof EPR insulated, flexible power cable of size 4 Core X 1.5 sq.mm.
*Use rated current when sizing control equipment."

f6="17 KG (Approx) without Cable"





l1="Casted Parts : Aluminum Alloy LM4"

l2="Outer Casing : Stainless Steel SS304"

l3="Motor Casing : Aluminum Alloy LM4"

l4="Motor Shaft : Stainless Steel SS431"

l5="Impeller : Stainless Steel SS410,"

l6="Hardened (HRC-55)"

l7="Fasteners : Stainless Steel"

l8="Oil Chamber : Aluminum Alloy"

l9="Diffuser : Nitrile Rubber Coated"

l10="Strainer : Stainless Steel with circular holes of 6mm X 6mm."






l11="Max. Submergence depth-10 Mtr."

l12="Max. Liquid Temperature-400C."

l13="*External control panel is mandatory for Single Phase pump."


/>




    </div>
  )
}

export default Ak5150m