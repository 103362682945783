import React from 'react';
import Nav from './../Component/Nav/Nav';
import Carousel from './../Component/Home/Carousel';
import Cpro from './../Component/Home/Cpro';
import Commit from './../Component/Home/Commit';
import Products from './../Component/Home/Productscards';
import Gallery from './../Component/Home/Patronslider';
import Foot from './../Component/Footer/Footer';
import Wcy from './../Component/Home/Wcy';
import Rsli from './../Component/Home/Rsli';
import Apli from './../Component/Home/Apli';
import Qual from './../Component/Home/Qual';
import Wlc from './../Component/Home/Wlc';

import Nb from './../Component/Nt/Nb';
import { useEffect } from "react";


const Home = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
},[])
  return (
    <div>

<Carousel></Carousel>
<Wlc></Wlc>
{/*<Nb></Nb>*/}
<Cpro></Cpro>
<Commit></Commit>
<Products></Products>
<Qual></Qual>
<Wcy></Wcy>
<Apli></Apli>
<Gallery></Gallery>
<Rsli></Rsli>
<Foot></Foot>


    </div>
  )
}

export default Home