import React from 'react';
import Nav from '../Component/Nav/Nav';
import Sepro1 from '../Component/Products/AK5360m';
import Foot from '../Component/Footer/Footer';
import { useEffect } from "react";

const AK5360 = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
},[])

  return (
    <div>

<Sepro1></Sepro1>
<Foot></Foot>


    </div>
  )
}

export default AK5360