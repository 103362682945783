import React from 'react';
import Nav from './../Component/Nav/Nav';
import Foot from './../Component/Footer/Footer';
import Installation from './../Component/Facilities/Fac1';
import Transportation from './../Component/Facilities/Fac2';
import { useEffect } from "react";

const Facilities = () => {

  useEffect(()=>{
    window.scrollTo(0,0);


// Set meta title dynamically
document.title = 'Best Wastewater Solution Pump Manufacturer.  ';

// Set meta description dynamically
const metaDescription = document.createElement('meta');
metaDescription.name = 'description';
metaDescription.content = 'KPR’s is the Leading manufacturer of efficient dewatering, non-clog, and sewage pumps. Your trusted supplier and exporter for reliable wastewater solutions, Having Export, Supply, and Installation Facilities';
document.head.appendChild(metaDescription);

// Set meta keywords dynamically
const metaKeywords = document.createElement('meta');
metaKeywords.name = 'keywords';
metaKeywords.content = 'Supplier of Pumps in Pune | Waste Management Pumps Exporter in Pune |Installation Services of waste management pumps in Pune. '; // Add your keywords here
document.head.appendChild(metaKeywords);


const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'Best Wastewater Solution Pump Manufacturer. ';
    document.head.appendChild(ogTitle);

    // Set Open Graph description dynamically
    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'KPR’s is the Leading manufacturer of efficient dewatering, non-clog, and sewage pumps. Your trusted supplier and exporter for reliable wastewater solutions, Having Export, Supply, and Installation Facilities';
    document.head.appendChild(ogDescription);

    // Set Open Graph keywords dynamically
    const ogKeywords = document.createElement('meta');
    ogKeywords.property = 'og:keywords';
    ogKeywords.content = 'Supplier of Pumps in Pune | Waste Management Pumps Exporter in Pune |Installation Services of waste management pumps in Pune.'; // Add your keywords here
    document.head.appendChild(ogKeywords);


// Cleanup on component unmount
return () => {
  document.title = 'Default Title'; // Reset the title if needed
  document.head.removeChild(metaDescription);
  document.head.removeChild(metaKeywords);
  document.head.removeChild(ogTitle);
  document.head.removeChild(ogDescription);
  document.head.removeChild(ogKeywords);
};



},[])

  return (
    <div>
        

<Installation></Installation>
<Transportation></Transportation>
<Foot></Foot>


    </div>
  )
}

export default Facilities