import React from 'react';
import classes from './Gallery.module.css';

/*ownership*/

import i1 from './../../Assets/g1 (1).avif';
import i2 from './../../Assets/dukan.jpg';
import i4 from './../../Assets/g1 (2).jpg';
import i6 from './../../Assets/g1 (4).jpg';
import i11 from './../../Assets/g1 (9).jpg';
import i24 from './../../Assets/sDfd.jpg';


/*Installation and transportation*/
import i20 from './../../Assets/ins1.jpg';
import i21 from './../../Assets/ins2.jpg';
import i22 from './../../Assets/ins3.jpg';
import i23 from './../../Assets/quatar.jpg';
import i25 from './../../Assets/t1.jpg';
import i26 from './../../Assets/trasportation.jpg';


/*clear product*/
import i27 from './../../Assets/p1.png';
import i28 from './../../Assets/p2.png';
import i29 from './../../Assets/p3.png';
import i30 from './../../Assets/p4.png';
import i31 from './../../Assets/p5.png';
import i19 from './../../Assets/i21.png';
import i32 from './../../Assets/prop1.png';
import i33 from './../../Assets/prop2.png';
import i34 from './../../Assets/prop3.png';
import i35 from './../../Assets/prop4.png';
import i36 from './../../Assets/prop8.png';


/* product*/
import i3 from './../../Assets/g1 (1).jpg';
import i5 from './../../Assets/g1 (3).jpg';
import i7 from './../../Assets/g1 (5).jpg';
import i8 from './../../Assets/g1 (6).jpg';
import i18 from './../../Assets/i1.JPG';
import i9 from './../../Assets/g1 (7).jpg';
import i10 from './../../Assets/g1 (8).jpg';
import i12 from './../../Assets/g1 (10).jpg';
import i13 from './../../Assets/g1 (11).jpg';
import i14 from './../../Assets/g1 (12).jpg';
import i15 from './../../Assets/g1 (13).jpg';
import i16 from './../../Assets/g1 (14).jpg';
import i17 from './../../Assets/g1 (15).jpg';



import ni from './../../Assets/ni.jpg';






const Gallery = () => {
  return (
    <div className={classes.qa}>
<div className={classes.aq}>  

<center>
    <br/> 
<h3 className={classes.head}>OUR <span className={classes.heads}>GALLERY</span></h3>

<br/>
<h3 className={classes.head}>OUR <span className={classes.heads}>PUMPS</span></h3>

<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={ni}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>


<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div>


<div className={classes.col}>
    
    <img className={classes.mg}  src={i31}></img>
    <p className={classes.pp}></p>

</div>


<div className={classes.col}>
    

    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div> 
    

</div>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i19}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg} src={i33}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg} src={ni}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i35}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg} src={i36}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg} src={i3}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i5}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg} src={i7}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg} src={ni}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>

<br/>
<h3 className={classes.head}>OTHER <span className={classes.heads}>PRODUCTS</span></h3>


<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i18}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg} src={i9}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg} src={i10}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={ni}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>




<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i15}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={i16}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg}  src={i17}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>


<br/>
<h3 className={classes.head}>OUR <span className={classes.heads}>INFRASTRUCTURE</span></h3>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={ni}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={i2}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    
  <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i6}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg}  src={i24}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>


<br/>
<h3 className={classes.head}>INSTALLATION WI<span className={classes.heads}>TH TRANSPORTATION</span></h3>

<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i20}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={ni}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg}  src={i22}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>



<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i23}></img>
    <p className={classes.pp}></p>

</div>



<div className={classes.col}>
    
    <img className={classes.mg}  src={i25}></img>
    <p className={classes.pp}></p>

</div>




<div className={classes.col}>
    

    
    <img className={classes.mg}  src={i26}></img>
    <p className={classes.pp}></p>

</div> 
    
  

</div>

</center>
</div>



    </div>
  )
}

export default Gallery