import React from 'react';
import classes from './Ab5.module.css';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Abt5 = () => {
  return (
    <div className={classes.hold}>

<section className={classes.container}>
        <div className={classes.left}><Fade left>
        <h2 className={classes.heading}>OUR<span className={classes.heads}> VISION</span></h2>
            <p className={classes.leftp}>&emsp;&emsp;&emsp; To be the Leading Global Supplier of Submersible Dewatering pump Systems and services in Partnership with our customers to Enable them to consistently produce at the lowest Cost over the life Cycle of the Equipment.</p>
            </Fade> </div>

        <div className={classes.right}><Fade right>
        <h2 className={classes.heading}>OUR<span className={classes.heads}> PLEDGE</span></h2>
            <p className={classes.leftp}> Quality and reliability in all our products <br></br>
Strong service and support after the sale <br></br>
Lower total life cycle equipment costs <br></br>
Maximum value for your investment.</p>
        </Fade></div>
    </section>

    </div>
  )
}

export default Abt5