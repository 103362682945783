import React from 'react';
import Nav from './../Component/Nav/Nav';
import Foot from './../Component/Footer/Footer';
import Cli from './../Component/Clients/Cli';
import { useEffect } from "react";


const About = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
},[])
  return (
    <div>
        

<Cli></Cli>
<Foot></Foot>


    </div>
  )
}

export default About