import React from 'react';
import Nav from './../Component/Nav/Nav';
import Foot from './../Component/Footer/Footer';
import Form from './../Component/Enquiry/Form';
import { useEffect } from "react";

const Enquiry = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
},[])
  return (
    <div>
        
<Form></Form>
<Foot></Foot>


</div>
  )
}

export default Enquiry