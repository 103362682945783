import React from 'react';
import Nav from './../Component/Nav/Nav';
import Op from './../Component/Products/Op';
import Op2 from './../Component/Products/Op2';
import Pro1 from './../Component/Products/Pro1';
import Pro2 from './../Component/Products/Pro2';
import Pro3 from './../Component/Products/Pro3';
import Pro4 from './../Component/Products/Pro4';
import Pro5 from './../Component/Products/Pro5';
import Foot from './../Component/Footer/Footer';
import { useEffect } from "react";


const Products = () => {

  useEffect(()=>{
    window.scrollTo(0,0);


// Set meta title dynamically
document.title = 'KPR’s is Best Wastewater Treatment Pumps Manufacturer in Pune';

// Set meta description dynamically
const metaDescription = document.createElement('meta');
metaDescription.name = 'description';
metaDescription.content = 'Kprs Pumps is a prominent manufacturer, exporter, and supplier specializing in slurry pumps, catering to industries that require efficient handling of abrasive and high-density slurries';
document.head.appendChild(metaDescription);

// Set meta keywords dynamically
const metaKeywords = document.createElement('meta');
metaKeywords.name = 'keywords';
metaKeywords.content = 'Top 10 Dewatering Pump Manufacturer in Pune | Best Non-clog Pump Manufacturing company | Industrial Waste Treatment Plants in Maharashtra | Sewage Pumps Production Company |Slurry Pumps Making company in Pune.| Submersible Dewatering Pump Manufacturer |Electric driven Submersible Dewatering Pump Manufacturer in Pune |Sump Pump in Pune|'; // Add your keywords here
document.head.appendChild(metaKeywords);


const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'KPR’s is Best Wastewater Treatment Pumps Manufacturer in Pune';
    document.head.appendChild(ogTitle);

    // Set Open Graph description dynamically
    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'Kprs Pumps is a prominent manufacturer, exporter, and supplier specializing in slurry pumps, catering to industries that require efficient handling of abrasive and high-density slurries';
    document.head.appendChild(ogDescription);

    // Set Open Graph keywords dynamically
    const ogKeywords = document.createElement('meta');
    ogKeywords.property = 'og:keywords';
    ogKeywords.content = 'Top 10 Dewatering Pump Manufacturer in Pune | Best Non-clog Pump Manufacturing company | Industrial Waste Treatment Plants in Maharashtra | Sewage Pumps Production Company |Slurry Pumps Making company in Pune.| Submersible Dewatering Pump Manufacturer |Electric driven Submersible Dewatering Pump Manufacturer in Pune |Sump Pump in Pune|'; // Add your keywords here
    document.head.appendChild(ogKeywords);


// Cleanup on component unmount
return () => {
  document.title = 'Default Title'; // Reset the title if needed
  document.head.removeChild(metaDescription);
  document.head.removeChild(metaKeywords);
  document.head.removeChild(ogTitle);
  document.head.removeChild(ogDescription);
  document.head.removeChild(ogKeywords);
};



},[])



  return (
    <div>

<Op></Op>
<Pro1></Pro1>
<Op2></Op2>
<Pro2></Pro2>
<Pro3></Pro3>
<Pro4></Pro4>
<Pro5></Pro5>
<Foot></Foot>


    </div>
  )
}

export default Products