import React from 'react';
import Serpo from './Sepro';

import logo1 from './../../Assets/p1.png';
import logo2 from './../Products/img/aksmall.jpg';

const AKsm = () => {
  return (
    <div>
<Serpo 
h="Electric Submersible Dewatering Pumps"
sh="MODEL NO : AK Small Series (AK-0.5 Series)"
img1={logo1} 
img2={logo2} 

ih="AK Small Series (AK-0.5 Series)"
ip="KPR`S dewatering submersible pumps, known for job site dependability. Pumps are constructed of strong die cast aluminum and stainless steel body parts; " 



f11=" Designed for residential, commercial and industrial application where sewage, effluent discharge and storm water drainage are essential."

f21="Effectively drains sumps, basements and construction sites "

f31="Rapidly dewaters swimming pools, spas, hot tubs tanks and other water storage structures."

f41="Ideal for water fountains, sprinkler systems, aquariums and irrigation."


f51="Quit & efficient way to transfer water from ground level to overhead tanks in house and bungalows"







l1="Pumping Fluid : Cleaning water, muddy water, sump water  | 40' C-"

l2="Pump Material : Polycorbonate |
Silicon Carbide |
SKF Upper & Lower |
Aluminum Alloy LM-4 |
Aluminum Alloy LM-4"

l3="Motor : Dry type, induction motor, 2-poles |
Class F |
Single Phase/115V/60Hz |
SS 410 |
3mtr. PVC insulated with 3pin plug"

l4="Discharge Connection : 1”/ NPT Threading type"







l11="
Model :AK-0.3-2P |
HP:	0.3| 
PH: Single| 
Voltage:115V| 
Total Head (Max) ft: 24'| 
Capacity (Max) gal/min:52| 
Rated Current Amp:6| 
Starting Method: Capacitor|
Float:Incl|
Total Hight:297mm|
Wt.: 8kg"

l12="Model :AK-0.5-2P |
HP:	0.5| 
PH: Single| 
Voltage:115V| 
Total Head (Max) ft: 33'| 
Capacity (Max) gal/min:59| 
Rated Current Amp:7| 
Starting Method: Capacitor|
Float:Incl|
Total Hight:312mm|
Wt.: 9kg"


/>




    </div>
  )
}

export default AKsm