import React from 'react';
import classes from './Op2.module.css';
import bbd from './../../Assets/p5.png';
import { Link } from 'react-router-dom';

import SK3350 from './../Products/Pdf/sk3350.pdf';
import SK6350 from './../Products/Pdf/sk6350.pdf';
import SK8350 from './../Products/Pdf/sk8350.pdf';
import SK10350 from './../Products/Pdf/sk10350.pdf';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Op2 = () => {
  return (
    <div className={classes.Pro2}>

<div className={classes.Prw}>
    


        <div className={classes.l1}><Fade left>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> NON-CLOG<span className={classes.heads1}> PUMPS</span>
        </h4>
       
       
        <div className={classes.wrapper}>
 
        <p className={classes.p2}>

<p> &emsp;KPR's Pumps offers a versatile range of non-clog pumps, spanning from 3 HP to 10 HP, designed to tackle demanding wastewater and sewage applications. These pumps are engineered with cutting-edge impeller technology and efficient hydraulic systems to prevent clogging and maintain optimal performance. They excel in scenarios where solids-laden fluids need to be handled, making them a reliable choice for municipal sewage systems, industrial wastewater treatment plants, and situations with debris or solid particles.
</p>

<p>
 &emsp;Built with durability in mind, KPR's non-clog pumps feature robust construction and corrosion-resistant materials to withstand harsh operational conditions. Their low-maintenance design and high efficiency make them a cost-effective solution for organizations seeking dependable wastewater management tools. KPR Pumps' unwavering commitment to quality ensures that these non-clog pumps meet or exceed industry standards, making them the preferred choice for efficiently and effectively handling challenging wastewater and sewage challenges in both municipal and industrial settings.
 </p>
  </p>

 </div>





 </center></Fade>
        </div>

        <div className={classes.r1}><Fade right>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

</div>
    </div>
  )
}

export default Op2;