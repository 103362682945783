import React from 'react';
import classes from "./Cpro.module.css";
import logo from './../../Assets/logo.png';
import sDfd from "./../../Assets/sDfd.jpg";
import Fade from 'react-reveal/Zoom';

const Cpro = () => {
  return (
    <div className={classes.Hold}>
<center>
  <br/>
<h1 className={classes.h15}><span className={classes.kes}>COMPANY</span> <span className={classes.nil}> PROFILE </span></h1></center>
<Fade>
<div id="handmaid" className={classes.book}>
  <div className={classes.gloss}></div>
  
  <div className={classes.cover}> <center> <img className={classes.img} src={logo}/><br></br>

  <br></br><p className={classes.h1}>WE HAVE <span className={classes.ni}>20+ </span> YEARS OF EXPERIENCE</p> </center> 
<center>
  <p className={classes.h11}>We Are Manufacturer, Exporter, Supplier of Dewatering Pumps, Sewage Pumps, Non Clog Pumps, Slurry Pumps, Electric Submersible Dewatering Systems, From Pune, Maharashtra, India</p>
  </center>

 </div>
 
  <div className={classes.description}>
    <h1 className={classes.h1}>KPR's PUMPS Tale</h1>
  <center>
    <ul className={classes.ul}>
<li>Optimize pump designs for maximum efficiency and performance.</li>
<li>Choose high-quality materials for pump components.</li>
<li>Adapt product lines to changing market conditions.</li>
<li>Implement_precision manufacturing processes to maintain consistent quality and reliability.</li>
<li>Develop eco-friendly pump designs when possible.</li>
</ul>
  </center>
  </div>
</div>
<br></br>





</Fade>

    </div>
  )
}

export default Cpro