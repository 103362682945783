import React from 'react';
import classes from './Ab4.module.css';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Ab4 = () => {
  return (
    <div className={classes.hold}>

<Zoom>
<div className={classes.section}>
        <div className={classes.division}>
          <Fade left>
            <h3 className={classes.prof}>Profile</h3>
            <p className={classes.leftps}> Name of the Organization:   KPR'S PUMPS</p>
            <p className={classes.leftps}> Nature of the Organization:   Proprietorship</p>
            <p className={classes.leftps}> Proprietor:   Mr. Ashwin R. Kolhe</p>
            <p className={classes.leftps}> Founded in:   2007</p>
            </Fade>
        </div>
        <div className={classes.division}>
        <Fade left>
            <h3 className={classes.prof}>Staff</h3>
            <p className={classes.leftps}> Management:   3</p>
            <p className={classes.leftps}> Manufacturing:   10-12</p>
            <p className={classes.leftps}> Marketing Engineer:   5</p>
            <p className={classes.leftps}> Technician:   3</p>
            <p className={classes.leftps}> Service Team:   4</p>
            </Fade>
        </div>
        <div className={classes.division}>
          <Fade left>
            <h3 className={classes.prof}>Business</h3>
            <p className={classes.leftps}> Domestic Business:   40%</p>
            <p className={classes.leftps}> Export Business:   60%</p>
            </Fade>
        </div>
    </div>
    </Zoom>

    </div>
  )
}

export default Ab4