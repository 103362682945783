import React, { Component } from "react";
import classes from './Patronslider.module.css';
import Slider from "react-slick";
import Patron from "./Patron";

import img1 from './../../Assets/p1.png';
import img2 from './../../Assets/p2.png';
import img3 from './../../Assets/p3.png';
import img4 from './../../Assets/p4.png';
import img5 from './../../Assets/p5.png';
import img6 from './../../Assets/prop1.png';
import img7 from './../../Assets/prop2.png';
import img8 from './../../Assets/prop3.png';
import img9 from './../../Assets/prop4.png';
import ni from './../../Assets/ni.jpg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Patronslider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
               {
                 breakpoint: 400,
                 settings: {
                   slidesToShow: 1,
                   slidesToScroll: 1,
                   infinite: true,
                   dots: true
                 }
               }]
        };
        return (
            <div className={classes.hold}>
                <h3 className={classes.head}>OUR <span className={classes.heads}>GALLERY</span></h3>
                <Slider {...settings}>
                    <div>
                     {/*   <Patron className={classes.bb} img={img1}></Patron>    */}    <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img2} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img3} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img4} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                      {/*   <Patron img={img5} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img6} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img7} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img8} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>
                    <div>
                       {/*  <Patron img={img9} ></Patron>*/}        <Patron img={ni} ></Patron>
                    </div>

                </Slider>
                <br/>  <br/>
            </div>
            
        );
    }
}
