import React from 'react';
import classes from './Pro3.module.css';
import bbd from './../../Assets/prop3.png';

import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';



const Pro3 = () => {
  return (
    <div className={classes.Pro1}>

<div className={classes.Prw}>
        <div className={classes.l1}><Fade left>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

        <Fade right>
        <div className={classes.r1}>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> SEWAGE<span className={classes.heads1}> PUMPS</span>
        </h4>
       


        <div className={classes.wrapper}>
        <p className={classes.p2}>

      <p> &emsp; Kpr's Pumps is a reputable manufacturer, exporter, and supplier specializing in sewage pumps, catering to the wastewater management industry. With years of experience and expertise, they have established themselves as a trusted source for sewage pump solutions. Their product range includes a wide variety of pumps designed to efficiently handle sewage and wastewater, ensuring reliable and effective waste disposal.
      </p>

      <p>
       &emsp; Kpr's Pumps is known for its commitment to quality, employing stringent quality control measures in the manufacturing process to deliver pumps that are durable and long-lasting. Additionally, their sewage pumps are designed to meet international standards, making them suitable for both domestic and industrial applications. The company's dedication to customer satisfaction and their comprehensive after-sales support further solidify their position as a top choice for sewage pump needs worldwide.
       </p>
        </p>


       
 
 
  
 
 </div> 
 </center>
        </div>
        </Fade>

</div>

    </div>
  )
}

export default Pro3