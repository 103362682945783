import React from 'react';
import classes from './Fac1.module.css';

import img from './../../Assets/ins2.jpg';
import img2 from './../../Assets/ins1.jpg';
import img3 from './../../Assets/ins3.jpg';

import ni from './../../Assets/ni.jpg';
import vdo from './../../Assets/installation.mp4';

import Fade from 'react-reveal/Fade';

const Fac1 = () => {
  return (
    <div className={classes.hot} >

<section>

<div className={classes.container}>
  
<div className={classes.middle}>


<Fade top><img src={img2} alt="KPR'S PUMPS" className={classes.riii}/></Fade>
<Fade bottom><img src={img3} alt="KPR'S PUMPS" className={classes.riii}/></Fade>

</div>


      <div className={classes.left}>

        <Fade top>
             <h2 className={classes.heading}>INSTAL<span className={classes.heads}>LATION</span></h2><br></br></Fade>
             <Fade left>     <p className={classes.leftp}>&emsp;&emsp;&emsp; If and only if the client requests installation, KPR'S PUMP offers the service of installation on the customer's premises. It is not feasible to offer this feature to the client if the location and time are not convenient for KPR's PUMPS. Installation and servicing costs apply.</p>   </Fade>
        </div>

     

        <div className={classes.right}><Fade right>
            <img src={ni} alt="KPR'S PUMPS" className={classes.ri}/>
        </Fade>
        </div>
    </div>

    <br></br>   <br></br>
    <Fade top>
            <video alt="KPR'S PUMPS"autoplay muted controls className={classes.rii}>
           
           <source src={vdo} type="video/mp4" />

                </video>
                </Fade>
    </section>





<br/><br/>


    </div>
  )
}

export default Fac1