
import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './../../Assets/logo.png';

export default function Wpp() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber="9552006841"
                accountName="KPR'S PUMPS"
                avatar={logo}
                chatMessage="Please feel free to ask your Pumps related questions, and I'll provide detailed responses here."
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}