import React from 'react';
import classes from './Ab6.module.css';

import img from "./../../Assets/rc.png";

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Ab6 = () => {
  return (
    <div className={classes.hold}>

<Zoom>
<section className={classes.container}>
   
        <div className={classes.right}>
        <h2 className={classes.heading}>REGISTRATION<span className={classes.heads}> CERTIFICATE</span></h2>
<br></br>
            <img src={img} alt="KPR'S PUMPS" className={classes.ri}/>
        </div>
    </section>

    </Zoom>
    </div>

    )
}

export default Ab6