import React from 'react';
import { Component } from "react";
import './Nb.css';

import logo from './../../Assets/logo.png';
import db from './../../Assets/db.png';
import { Link } from 'react-router-dom';



class Nb extends Component {
    state = { clicked: false };
    handleClick = () => {
        this.setState({
            clicked: !this.state.clicked
        })
    };
    render() {
        return (<div className="nvb">

<div className="mm">
<div className="email">
&emsp;&emsp;EMAIL : 
<a className="me" href="mailto:info@kprspumps.in"> info@kprspumps.in</a>

</div>

<div className="mob">
&emsp;&emsp;CONTACT : 
<a className="mn" href="tel:+919552006841">&nbsp;+91 9552006841 &nbsp;</a> /&nbsp;
<a className="mn" href="tel:+919623772704">&nbsp;+91 9623772704</a>
</div>
</div>

            <div className="navHold">

                    <nav className="navbarItems">

                        <img src={logo} className="comlogo2"></img>
                        <img src={db} className="comlogo3"></img>
                        <div className="menu-icons" onClick={this.handleClick}>
                            <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}> </i>
                        </div>
                        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                            <li className="list"><Link className="linker" to='/' span={true} smooth={true}>HOME</Link></li>
                            <li className="list"><Link className="linker" to='/About' span={true} smooth={true}>ABOUT</Link></li>
                            <li className="list"><Link className="linker" to='/Facilities' span={true} smooth={true}>FACILITIES</Link></li>
                            <li className="list"><Link className="linker" to='/Client' span={true} smooth={true}>CLIENTS</Link></li>


                            <li >
                                <div class="dropdown">
                                    <p class="dropdownContent"><Link className="linker" to='/Product' span={true} smooth={true}>PRODUCTS</Link></p>
                                    <div class="dropdown-content">
          
<ul>
    <li>
                                        <div class="dropdown2">
                                    <p class="dropdownContent2">DEWATERING PUMPS</p>
                                    <div class="dropdown-content2">
                                    <Link to='/AK5150' className="lii">AK-1-5150-2P</Link> 
                                    <Link to='/AK5350' className="lii">AK-1-5350-2P</Link> 
                                    <Link to='/AK5360' className="lii">AK-1-5360-2P</Link> 
                                    <Link to='/AK2350' className="lii">AK-2350-2P</Link> 
                                    <Link to='/AK3350' className="lii">AK-3350-2P</Link> 
                                    <Link to='/AK6350' className="lii">AK-6350-2P</Link> 
                                    <Link to='/AK8350' className="lii">AK-8350-2P</Link> 
                                    <Link to='/AK8350H' className="lii">AK-8350H-2P</Link> 
                                    <Link to='/AK10350' className="lii">AK-10350-2P</Link> 
                                    <Link to='/AK15350' className="lii">AK-15350-2P</Link> 
                                    <Link to='/AK35350' className="lii">AK-35350M-L-2P</Link>	 
                                    <Link to='/AKs' className="lii">AK-SMALL-SERIES</Link>	 


                                        </div>
                                </div>
    </li>

<li>
                                <div class="dropdown3">
                                    <p class="dropdownContent3">NON-CLOG PUMPS</p>
                                    <div class="dropdown-content3">
                                    <Link to='/SK3350' className="lii">SK 3350 NC</Link>
     <Link to='/SK6350' className="lii">SK 6350 NC</Link>
      <Link to='/SK8350' className="lii">SK 8350 NC</Link>
      <Link to='/SK10350' className="lii">SK 10350 NC</Link>
      


                                        </div>
                                </div>
</li>


</ul>
                                    </div>
                                </div>

                            </li>
                            <li className="list"><Link className="linker" to='/Gallery' span={true} smooth={true}>GALLERY</Link></li>
                            <li className="list"><Link className="linker" to='/Contact' span={true} smooth={true}>CONTACT</Link></li>
                            <li className="list"><Link className="linker" to='/Enquiry' span={true} smooth={true}>ENQUIRY</Link></li>


                        </ul>
                    </nav>
               
            </div >


        </div>
        )
    }
};

export default Nb;