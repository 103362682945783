import classes from './Aplicard.module.css';
/*import { Link } from 'react-router-dom';
import img1 from '../../Assets/corepnproduct.webp';
*/


const Aplicard = (props) => {
    return <div className={classes.contain }>

 <div className={classes.hold}>
    <br/>
            <div className={classes.imgHold}>
                <img src={props.img} className={classes.img} alt="KPR'S PUMPS"></img>
            </div>
            <br/>
            <div className={classes.headhold}>
                <span className={classes.head}>{props.head}</span><br></br>
                <span className={classes.head1}>{props.head1}</span>
            </div><div className={classes.triangle}></div>
        </div>


     
    </div>


};

export default Aplicard;