import React from 'react';
import classes from './Op.module.css';
import bbd from './../../Assets/prop3.png';

import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';



const Op = () => {
  return (
    <div className={classes.Pro1}>

<div className={classes.Prw}>
        <div className={classes.l1}><Fade left>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

        <Fade right>
        <div className={classes.r1}>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> DEWATERING<span className={classes.heads1}> PUMPS</span>
        </h4>
       


        <div className={classes.wrapper}>
        <p className={classes.p2}>

      <p> &emsp; KPR'S Pumps specializes in manufacturing, supplying, and exporting a wide range of dewatering pumps, ranging from 3 HP to 10 HP. These dewatering pumps are engineered to efficiently remove excess water from construction sites, mines, and other applications. With their robust construction and high-performance capabilities, KPR's dewatering pumps are the preferred choice for tackling water-related challenges in demanding environments.
      </p>

      <p>
       &emsp; These pumps are designed to handle varying water levels and can effectively pump out large volumes of water, ensuring a dry and safe working environment. KPR's commitment to quality and reliability extends to their dewatering pump range, making them a trusted partner for construction companies, mining operations, and other industries that require efficient water removal solutions. Whether it's for dewatering trenches, excavations, or controlling water in industrial settings, KPR's dewatering pumps provide the power and performance needed to get the job done.
       </p>
        </p>


       
 
 
  
 
 </div> 
 </center>
        </div>
        </Fade>

</div>

    </div>
  )
}

export default Op