import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import classes from './Nav.module.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';

import { Link } from 'react-router-dom';

import logo from './../../Assets/logo.png';
import db from './../../Assets/db.png';
import { Container } from 'react-bootstrap';

function MyNav() {

    /*    <Nav.Link ><Link to='/Readinfo' className={classes.li}>Readmore</Link></Nav.Link> */
    return (
    <div className={classes.nvb}>

<div className={classes.mm}>
<div className={classes.email}>
&emsp;&emsp;EMAIL : 
<a className={classes.me} href="mailto:info@kprspumps.in"> info@kprspumps.in</a>

</div>

<div className={classes.mob}>
&emsp;&emsp;CONTACT : 
<a className={classes.mn} href="tel:+919552006841">&nbsp;+91 9552006841 &nbsp;</a> /&nbsp;
<a className={classes.mn} href="tel:+919623772704">&nbsp;+91 9623772704</a>
</div>
</div>

        <Navbar collapseOnSelect  varient="light"  className={classes.nvb2}>
            <Container className={classes.metid}>
                <Navbar.Brand href="#home"><img src={logo} className={classes.img}></img></Navbar.Brand>
                &nbsp;&nbsp; &nbsp;&nbsp;<Navbar.Brand href="#home"><img src={db} className={classes.immg}></img></Navbar.Brand>
                &nbsp;&nbsp; &nbsp;&nbsp;<Navbar.Toggle bg="light" className={classes.tgl} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
             <Nav className={classes.meti}>
                        <Nav.Link ><Link to='/' className={classes.li}>Home</Link></Nav.Link>
                        <Nav.Link ><Link to='/About' className={classes.li}>About</Link></Nav.Link>
                        <Nav.Link ><Link to='/Facilities' className={classes.li}>Facilities</Link></Nav.Link>
                        <Nav.Link ><Link to='/Client' className={classes.li}>Clients</Link></Nav.Link>
                        <Nav.Link ><Link to='/Product' className={classes.li}>Products<NavDropdown>
                        
                        
                        <Dropdown>
      <Dropdown.Toggle variant="none" id="dropdown">
        DEWATERING PUMPS
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item><Link to='/AK5150' className={classes.lii}>AK-1-5150-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK5350' className={classes.lii}>AK-1-5350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK5360' className={classes.lii}>AK-1-5360-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK2350' className={classes.lii}>AK-2350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK3550' className={classes.lii}>AK-3350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK6350' className={classes.lii}>AK-6350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK8350' className={classes.lii}>AK-8350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK8350H' className={classes.lii}>AK-8350H-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK10350' className={classes.lii}>AK-10350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK15350' className={classes.lii}>AK-15350-2P</Link></Dropdown.Item>
        <Dropdown.Item><Link to='/AK35350' className={classes.lii}>AK-35350M-L-2P</Link>	</Dropdown.Item>
        <Dropdown.Item><Link to='/AKs' className={classes.lii}>AK-SMALL-SERIES</Link>	</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>



              <NavDropdown.Divider />

              <Dropdown>
      <Dropdown.Toggle variant="none" id="dropdown-basic">
        NON-CLOG PUMPS
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item><Link to='/SK3350' className={classes.lii}>SK 3350 NC</Link></Dropdown.Item>
      <Dropdown.Item><Link to='/SK6350' className={classes.lii}>SK 6350 NC</Link></Dropdown.Item>
      <Dropdown.Item><Link to='/SK8350' className={classes.lii}>SK 8350 NC</Link></Dropdown.Item>
      <Dropdown.Item><Link to='/SK10350' className={classes.lii}>SK 10350 NC</Link></Dropdown.Item>
      
      </Dropdown.Menu>
    </Dropdown>
              
            </NavDropdown>
                        
                        </Link></Nav.Link>
                        <Nav.Link ><Link to='/Gallery' className={classes.li}>Gallery</Link></Nav.Link>
                        <Nav.Link ><Link to='/Contact' className={classes.li}>Contact</Link></Nav.Link>
                        <Nav.Link ><Link to='/Enquiry' className={classes.li}>Enquiry</Link></Nav.Link>
                     






                     {/*} <span className={classes.rajja}>  <a className={classes.ab} href="mailto:info@primezonecs.com">info@primezonecs.com</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className={classes.ab} href="tel:9158586860">+91&nbsp;9158586860</a> </span>  
                        &nbsp;&nbsp; &nbsp;&nbsp;<Navbar.Brand href="#home"><img src={db} className={classes.immg}></img></Navbar.Brand>*/}
    </Nav>  

                </Navbar.Collapse>
            </Container>
        </Navbar>
   
        </div>
    );
}

export default MyNav;