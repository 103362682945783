import React from 'react';
import classes from './Pro4.module.css';
import bbd from './../../Assets/prop8.png';
import { Link } from 'react-router-dom';

import SK3350 from './../Products/Pdf/sk3350.pdf';
import SK6350 from './../Products/Pdf/sk6350.pdf';
import SK8350 from './../Products/Pdf/sk8350.pdf';
import SK10350 from './../Products/Pdf/sk10350.pdf';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Pro1 = () => {
  return (
    <div className={classes.Pro2}>

<div className={classes.Prw}>
    


        <div className={classes.l1}><Fade left>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> SLURRY<span className={classes.heads1}> PUMPS</span>
        </h4>
       
       
        <div className={classes.wrapper}>
 
        <p className={classes.p2}>

<p> &emsp;Kpr's Pumps is a prominent manufacturer, exporter, and supplier specializing in slurry pumps, catering to industries that require efficient handling of abrasive and high-density slurries. With a strong focus on engineering excellence, they have built a reputation for delivering robust and reliable slurry pump solutions. Their extensive product range encompasses a wide variety of slurry pumps designed to withstand the toughest operational conditions, ensuring optimal performance and longevity.
</p>

<p>
 &emsp; Kpr's Pumps is renowned for its commitment to quality assurance, incorporating rigorous quality control measures in the manufacturing process to produce pumps that excel in demanding applications. These slurry pumps are designed to meet international standards and are suitable for applications in mining, mineral processing, and other heavy-duty industries. The company's dedication to customer satisfaction and comprehensive after-sales support make them a preferred choice for clients seeking dependable slurry pump solutions worldwide.
 </p>
  </p>

 </div>





 </center></Fade>
        </div>

        <div className={classes.r1}><Fade right>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

</div>
    </div>
  )
}

export default Pro1