import classes from './Products.module.css';
import { Link } from 'react-router-dom';

/*import { Link } from 'react-router-dom';
import img1 from '../../Assets/corepnproduct.webp';
*/
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


const Products=(props)=>{
    return <div><div className={classes.contain }>
<Zoom>
    <Fade Top> <Link to='/Product' className={classes.li} >
 <div className={classes.hold}>
            <div className={classes.imgHold}>
                <img src={props.img} className={classes.img} alt="KPR'S PUMPS"></img>
            </div>

        </div>
        <br></br>
     <div className={classes.headhold}>
     <span className={classes.head}>{props.head}</span>
 </div>
 </Link>
        </Fade>
        </Zoom>
 </div>
 </div>

};


export default Products;