import { Outlet } from "react-router-dom";
import Navi from './../Component/Nav/Nav';
import Wpp from './../Component/Home/Wpp';

import Nav from './../Component/Nt/Nb';

const RootLayout = () => {
    return <div>
        <Nav></Nav>
        <br/> <br/> <br/> <br/> <br/><br/>
        <Outlet></Outlet> 
        <Wpp></Wpp>
    </div>
};

export default RootLayout;