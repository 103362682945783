import React from 'react';
import Serpo from './Sepro';

import logo1 from './../../Assets/p5.png';
import logo2 from './../Products/img/sk6350.jpg';

const SK3350m = () => {
  return (
    <div>
<Serpo 
h="Non-Clog Pumps"
sh="MODEL NO :  ( SK-6350-NC )"
img1={logo1} 
img2={logo2} 

ih="About  SK-6350-NC "
ip="KPR`S Non-Clog submersible pumps, known for job site dependability. Pumps are constructed of strong die cast aluminum and stainless steel body parts; they are fitted with stainless steel hardened impellers. The both upper and lower mechanical seals are wholly enclosed in oil chamber. These tough pumps do not have to be totally submerged and will operate under snore conditions if necessary." 


ms="Double seals of Inner lip seal & Outer Tungsten Carbide Vs Tungsten Carbide and are wholly enclosed in oil chamber."

f1="6HP (4.5KW)/3Ø/50Hz/415Volt, 2800 RPM DOL Start squirrel cage continuous rated induction
motor, insulation Class H (1800C)."

f2="9 Amps @ 415 Volt/3Ø/50Hz Ac Supply"

f3="Max.15 per hour"

f4="3”Aluminium, Hose type."


f5="15 meter, Water proof PVC/EPR insulated, flexible power cable of size 4 Core X 2.5 sq.mm.
*Use rated current when sizing control equipment."

f6="45 KG (Approx) without Cable"





l1="Casted Parts : Aluminum Alloy LM4"

l2="Motor Casing : Aluminum Alloy LM4"

l3="Pump Volute : Stainless Steel"

l4="Motor Shaft : Stainless Steel SS431"

l5="Impeller : Stainless Steel SS410,"

l6="Hardened (HRC-55)"

l7="Outlet :  Aluminum Alloy"

l8="Wear Plate : Stainless Steel."

l9="Fasteners : Stainless Steel  SS304"

l10="Oil Chamber : Aluminum Alloy  LM4"





l11="Max. Submergence depth-10 Mtr."

l12="Max. Liquid Temperature-400C."


l13="**These pumps must be used with external motor protection in accordance with technical data."


/>




    </div>
  )
}

export default SK3350m