import React from 'react';
import classes from './Wlc.module.css';

import w1 from './../../Assets/wlc1.png';
import w2 from './../../Assets/wlc2.png';
import w3 from './../../Assets/wlc3.png';
import w4 from './../../Assets/wlc4.png';
import i from './../../Assets/p2.png';
import n from './../../Assets/ni.jpg';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


const Wlc = () => {
  return (
    <div>
<div className={classes.wlc}>


<div className={classes.wl}>
      <Fade Top>
<center>
<img src={n} className={classes.i}></img>
</center>
</Fade>
</div>


<Zoom>
<div className={classes.wr}>

<h6 className={classes.head}>WELCOME <span className={classes.heads}>TO </span>KPR'S <span className={classes.heads}>PUMPS </span></h6>

<p className={classes.par}> &emsp;&emsp;&emsp;&emsp;100% commitment and a demonstration Our scientific prowess gave rise to the legendary KPR's Pumps. Quality has been compromised from start to finish WATCHWORD</p>


<div className={classes.blks}>

<div className={classes.row}>



<div className={classes.b1}>

    <div className={classes.im}>

          <img src={w1} className={classes.img} />

    </div>

    <div className={classes.im2}>

          <p className={classes.bh1} > Manufacturer </p> 
          <p className={classes.bh2} > Do it Now and well has taken to us to the highest positions.</p>

    </div>

</div>




<div className={classes.b1}>

<div className={classes.im}>

          <img src={w2} className={classes.img} />

    </div>

    <div className={classes.im2}>

          <p className={classes.bh1} > Exporter </p>
          <p className={classes.bh2} > Exporting attributes and qualities impact meeting criteria.</p>

    </div>



</div>

</div>

<div className={classes.row}>

<div className={classes.b1}>

<div className={classes.im}>

          <img src={w4} className={classes.img} />

    </div>

    <div className={classes.im2}>

          <p className={classes.bh1} > Quality </p>
          <p className={classes.bh2} > Features and qualities influence meeting express demands </p>

    </div>


</div>

<div className={classes.b1}>

<div className={classes.im}>

          <img src={w3} className={classes.img} />

    </div>

    <div className={classes.im2}>

          <p className={classes.bh1} > Supplier </p>
          <p className={classes.bh2} >Qualities of the supplier affect how it can meet demands. </p>

    </div>

</div>


</div>


</div>



</div>

</Zoom>




    </div>
    </div>
  )
}

export default Wlc