import React from 'react';
import classes from './Ab1.module.css';

import Fade from 'react-reveal/Fade';


import img from "./../../Assets/dukan.jpg";

const Ab1 = () => {
  return (
    <div>

<section className={classes.container}>
        <div className={classes.left}>
          <br/>
<Fade top>
          <h2 className={classes.heading}>INTROD<span className={classes.heads}>UCTION</span></h2> </Fade>
          <Fade left>
            <p className={classes.leftp}>&emsp;&emsp;&emsp; KPR'S PUMPS has more than 10 years of experience in submersible technology and has created a number of patented engineering and design advancements to increase the effectiveness and performance of equipment throughout this time. Dewatering pumps ranging from 1.5 HP to 50 HP are part of our wide product offering. Equipment from KPR'S PUMPS is effectively employed in applications for wastewater, storm water, industrial, and power facilities, including nuclear power plants.

The corporation maintains branch offices in Bahrain and Dubai. Presently, the company exports 40% of its goods to GCC nations and used reputable construction firms to supply the pumps for high-profile projects in Dubai and Abu Dhabi, Dubai, Qatar. Pumps are operating in excellent shape.</p> </Fade>
        </div>
        <div className={classes.right}><Fade right>
      <img src={img} alt="KPR'S PUMPS" className={classes.ri}/> </Fade>
        </div>
    </section>

    </div>
  )
}

export default Ab1