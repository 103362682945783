import React from 'react';
import classes from './Ab2.module.css';

import Fade from 'react-reveal/Fade';

import img from "./../../Assets/sDfd.jpg";

const Ab2 = () => {
  return (
    <div className={classes.hold}>

<br/>
<section className={classes.container}>

<div className={classes.right}>
  <Fade left>
            <img src={img} alt="KPR'S PUMPS" className={classes.ri}/>
            </Fade>  </div>
        <div className={classes.left}>
<Fade top><br/>
          <h2 className={classes.heading}>OUR<span className={classes.heads}> ASSURANCE</span></h2></Fade>
          <Fade right>
            <p className={classes.leftp}>&emsp;&emsp;&emsp; AT KPR'S PUMPS, Every pump is tested and checked before it leaves the factory to ensure dependable performance and operations on the field. Our knowledgeable customer service network is there for you after our product leaves the factory, offering a full range of support services.

Lower total life cycle costs for your submersible equipment are the consequence of the high quality, dependability, and overall performance of our products, along with our committed service support. The majority of the pump's components are made of stainless steel, while a small number of other components have PP and Epoxy coating on aluminium alloys for improved corrosion resistance.</p>
</Fade> </div>
<br/>
       
    </section>
<br/>
    </div>
  )
}

export default Ab2