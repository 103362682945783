import React from 'react';
import classes from './Cli.module.css';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Cli = () => {
  return (
    <div className={classes.hold}>
<br/>
<Zoom> <h3 className={classes.head}>OUR <span className={classes.heads}>CLIENTS</span></h3>  </Zoom>


<div className={classes.box}>



<div className={classes.row}>

<Fade left><h6 className={classes.jj}>  JSW STEEL LIMITED, PEN  </h6></Fade>
<Fade bottom><h6 className={classes.jj}> UTTAM GALVA </h6></Fade>
<Fade right><h6 className={classes.jj}>  VALCHAND INDUSTRIES  </h6></Fade>

</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}>  GOA MINERALS LTD.  </h6></Fade>
<Fade bottom><h6 className={classes.jj}>AXON CONSTRCUTIONS </h6></Fade>
<Fade right><h6 className={classes.jj}> STAR SHARDA ALLIANCE, Pune </h6></Fade>

</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}> RKM Powergen Pvt. Ltd. </h6></Fade>
<Fade bottom><h6 className={classes.jj}>  ONGC Hazira  </h6></Fade>
<Fade right><h6 className={classes.jj}> MES Pune  </h6></Fade>

</div>



<div className={classes.row}>

<Fade left><h6 className={classes.jj}>  LUNKAD REALITY, PUNE </h6></Fade>
<Fade bottom><h6 className={classes.jj}>  GODREJ & BOYCE MFG. CO. LTD., PUNE  </h6></Fade>
<Fade right><h6 className={classes.jj}>  M/S GVP INFRA PROJECTS PVT. LTD.  </h6></Fade>

</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}>  SILICA CERAMICA </h6></Fade>
<Fade bottom><h6 className={classes.jj}>  LEON REALTORS, PUNE  </h6></Fade>
<Fade right><h6 className={classes.jj}>  PERENNIAL TECHNOLOGIES PVT. LTD. </h6></Fade>

</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}>HARDUAGANJ THERMAL POWER STATION, KASIMPUR  </h6></Fade>
<Fade right><h6 className={classes.jj}>  R. A. GHULE INFRASTRCTURE COMPANIES, SINNER </h6></Fade>


</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}> BILT GRAPHICS PAPER PRODUCTS LTD.  </h6></Fade>
<Fade right><h6 className={classes.jj}> ROHAN BUILDERS & DEVELOPERS PVT. LTD., Pune  </h6></Fade>


</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}> CELERITY POWER , NIRA DEOGHAR HEP  </h6></Fade>
<Fade right><h6 className={classes.jj}> NARMADA OFFSHORE CONSTRUCTION COMPANY, PARADEEP IOCL   </h6></Fade>


</div>


<div className={classes.row}>

<Fade left><h6 className={classes.jj}> HAL-KO INFRASTRUCTURE COMPANY, MUMBAI   </h6></Fade>
<Fade right><h6 className={classes.jj}>CLASSIC MALL DEVELOPMENT CO. PVT. LTD., CHENNAI   </h6></Fade>


</div>


</div>

    </div>
  )
}

export default Cli