import React, { Component } from "react";
import classes from './Apli.module.css';
import Aplicard from './Aplicard';
import Fade from 'react-reveal/Fade';



import img2 from './../../Assets/pp1.png';
import img1 from './../../Assets/pp2.png';
import img3 from './../../Assets/pp3.png';
import img4 from './../../Assets/pp4.png';
import img5 from './../../Assets/pp5.png';
import img6 from './../../Assets/pp6.png';


const Apli=()=>{
return (
    <div className={classes.hold}><br/><br/>
        <h3 className={classes.head}>APPLI<span className={classes.heads}>CATIONS</span></h3>
        <br/><br/>
            <div className={classes.row}>
            <Fade left>
            <div>
                <Aplicard img={img1} head='RESIDENTIAL'></Aplicard>
            </div>
            </Fade>

            <Fade left>
            <div>
                <Aplicard img={img2} head='AGRICULTURE & BIOGAS'></Aplicard>
            </div>
            </Fade>

            <Fade top>
            <div>
                <Aplicard img={img3} head='INDUSTRIAL - STEEL, POWER, REFINARIES, MINNING SECTORS' className={classes.v}></Aplicard>
            </div>
            </Fade>

            <Fade bottom>
            <div>
                <Aplicard img={img4} head='CONSTRUCTION & INFRA'></Aplicard>
            </div>
            </Fade>
            
            
            <Fade right>
            <div>
                <Aplicard img={img5} head='WASTE MANAGEMENT'></Aplicard>
            </div>
            </Fade>

            <Fade right>
            <div>
                <Aplicard img={img6} head='FOUNTAINS'></Aplicard>
            </div>
            </Fade>
            </div>

            <br/><br/><br/>


    </div>
)
};

export default Apli;