import { useState } from 'react';
import './Sepro.css';
import icon from './../../Assets/p4.png';
import graph from './../../Assets/p4.png';

const Sepro = (props) => {
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };

    return (
        <div className='AccHold'>
<center>
<h3 className='h'>{props.h} </h3>
<h5 className='sh'>{props.sh}</h5>
<br></br>
         <div className='upimg'>
      <img className='img' src={props.img1} alt='KPRS PUMPS'></img>
      <img className='img' src={props.img2} alt='KPRS PUMPS'></img>

</div>
</center>

         
          <center>
            <br></br>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>INTRODUCTION</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>FEATURES</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>CAPABILITIES</button>
                <button className={r4 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd4}>LIMITATIONS</button>
            </div>
            <div className='right'>
                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                       <br></br>
                       <h4 className='ih'>{props.ih}</h4>
                       <br></br>
                       <p className='ip'>{props.ip}</p>
                  
                       <br></br>
                  <br></br>
                  
                       <h4 className='msh'>Mechanical Seal</h4>
                       <br></br>
                       <p className='ms'>{props.ms}</p>
                        <br></br>
                      
                    </div>
                   
                </div>}

                {r2 && <div className='right2'>
                <div className='accContainIn'>
                
                <h6 className='fh'>Motor Ratings</h6>
                <p className='fp'>{props.f1}</p>
           <hr className='fhr'></hr>

                <h6 className='fh'>Full Load Current</h6>
                <p className='fp'>{props.f2}</p>
                <hr className='fhr'></hr>             

                <h6 className='fh'>Start/Stop</h6>
                <p className='fp'>{props.f3}</p>
                <hr className='fhr'></hr>

                <h6 className='fh'>Discharge Connection</h6>
                <p className='fp'>{props.f4}</p>
                <hr className='fhr'></hr>

                <h6 className='fh'>Power Cable</h6>
                <p className='fp'>{props.f5}</p>
                <hr className='fhr'></hr>

                <h6 className='fh'>Weight</h6>
                <p className='fp'>{props.f6}</p>

                <p className='fp'>{props.f11}</p>
                <p className='fp'>{props.f21}</p>
                <p className='fp'>{props.f31}</p>
                <p className='fp'>{props.f41}</p>
                <p className='fp'>{props.f51}</p>
                    
                    </div>
                    
                </div>}

                {r3 && <div className='right3'>
                <div className='accContainIn'>
                   
                   <ul className='ul'>
                    <li className='li'>{props.l1}</li>
                    <hr className='fhr'></hr>
                    <li className='lib'>{props.l2}</li>
                    <hr className='fhr'></hr>
                    <li className='li'>{props.l3}</li>
                    <hr className='fhr'></hr>
                    <li className='lib'>{props.l4}</li>
                    <hr className='fhr'></hr>
                    <li className='li'>{props.l5}</li>
                    <hr className='fhr'></hr>
                    <li className='lib'>{props.l6}</li>
                    <hr className='fhr'></hr>
                    <li className='li'>{props.l7}</li>
                    <hr className='fhr'></hr>
                    <li className='lib'>{props.l8}</li>
                    <hr className='fhr'></hr>
                    <li className='li'>{props.l9}</li>
                    <hr className='fhr'></hr>
                    <li className='lib'>{props.l10}</li>

                   </ul>
                     
                    </div>
                    
                </div>}

                {r4 && <div className='right3'>
                <div className='accContainIn'>
                <ul>
                  <center>
                    <li className='li'>{props.l11}</li>
                    <hr className='fhr'></hr>
                    <li className='lib'>{props.l12}</li>
                    <hr className='fhr'></hr>
                    <li className='li'>{props.l13}</li>
                    
                    <li className='lib'>{props.l14}</li>
                    <li className='li'>{props.l15}</li>
                    </center>

                   </ul>
                    </div>
                   
                </div>}
            </div>
            </center>
        </div>

    )
}

export default Sepro;