import React from 'react';
import classes from './Cont.module.css';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import img from "./../../Assets/dukan.jpg";

const Cont = () => {
  return (
    <div>
<div>
<Zoom>
<h2 className={classes.heading}>CONTACT<span className={classes.heads}>US</span></h2>

</Zoom>

<section className={classes.container}>
<div className={classes.right}>
           <Fade left>
        <h2 className={classes.heading}>KPR'S<span className={classes.heads}>   PROPRIETORSHIP</span></h2>
            <p className={classes.leftp}>
            
            <br></br> 
            
            PROPRIETOR: <br></br> 
           <span className={classes.leftp1}> Mr. Ashwin Kolhe</span>

<br></br>
<br></br>

MOBILE NO :
<br></br>
<a href="tel:9552006841" className={classes.leftp1}>+ 91 9552006841</a>
 
 <br></br><br></br>



Email : <br></br>
<a href="mailto:ashwin@kprspumps.in" className={classes.leftp1}>ashwin@kprspumps.in</a>
            
            </p>
            </Fade>
        </div>


        <div className={classes.right}>
           <Fade right>
        <h2 className={classes.heading}>KPR'S<span className={classes.heads}>  SALES</span></h2>
            <p className={classes.leftp}>
            
            <br></br> 
            
            SALES PERSON : <br></br> 
           <span className={classes.leftp1}> Ms. Sharvari Kolhe</span>

<br></br>
<br></br>

MOBILE NO :
<br></br>
<a href="tel:9623772704" className={classes.leftp1}>+91 9623772704</a>
 
 <br></br><br></br>



Email : <br></br>
<a href="mailto:sharvari@kprspumps.in" className={classes.leftp1}>sharvari@kprspumps.in</a>
            
            </p>
            </Fade>
        </div>
    </section>

    </div>


    <div className={classes.left}>
<Fade top>
          <h2 className={classes.heading}>KPR'S<span className={classes.heads}>  PUMPS</span></h2>
            <p className={classes.leftp}>
                <br></br> 
            
            ADDRESS: <br></br> 
          
          <a className={classes.leftp1} href="https://www.google.com/maps/dir//KPR's+Pumps,+S+No+83,+Shed+5,+Ananat+Industrial+Estate,+next+to+Aggerwal+Godown,+Shivane,+Pune,+Maharashtra+411023/@18.4674122,73.7879877,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c065a7c56561:0xc7c48d87dac47246!2m2!1d73.790568!2d18.4674122?entry=ttu">
          
            Shed No.9, Sr.No. 82 / 6A, House No. 1527, Gurukrupa Industrial Estate,<br></br> Opp. Venkatesh Engg., Shivane, Pune - 411023, Maharashtra, India. </a><br></br>
<br></br>

MOBILE NO :
<br></br>
<a href="tel:9552006841" className={classes.leftp1}>+ 91 9552006841 </a>
<br></br><br></br>

{/*
 SALES : 
 <br></br>
 <a href="tel:9623772704" className={classes.leftp1}>+ 91 9623772704 </a>
<br></br><br></br>
  */}


EMAIL : <br></br>
<a href="mailto:info@kprspumps.in" className={classes.leftp1}>info@kprspumps.in</a>
            
            
            </p></Fade>
        </div>


    </div>
  )
}

export default Cont