import React from 'react';
import Serpo from './Sepro';

import logo1 from './../../Assets/prop3.png';
import logo2 from './../Products/img/ak15350.jpg';

const AK15350m = () => {
  return (
    <div>
<Serpo 
h="Electric Submersible Dewatering Pumps"
sh="MODEL NO :  ( AK-15350M/L-2P )"
img1={logo1} 
img2={logo2} 

ih="About   AK-15350M/L-2P "
ip="KPR'S dewatering submersible pumps, known for job site dependability. Pumps are constructed of strong die cast aluminum and stainless steel body parts; they are fitted with stainless steel hardened impellers and field adjustable nitrile rubber diffuser. The both upper and lower mechanical seals are wholly enclosed in oil chamber. These tough pumps do not have to be totally submerged and will operate under snore conditions if necessary. " 


ms="Double mechanical seals (Inner & Outer) in Tandem arrangement. Seal faces are Tungsten Carbide Vs Tungsten Carbide and are wholly enclosed in oil chamber."

f1="15HP (11.2KW)/3Ø/50Hz/415Volt, 2850 RPM DOL Start squirrel cage continuous rated
Induction motor, insulation Class H (1800C)."

f2="24 Amps @ 415 Volt/3Ø/50Hz Ac Supply "

f3="Max.15 per hour"

f4="4”/6” Aluminum Alloy, Hose type."


f5="15 meter, Water proof EPR insulated, flexible power cable of size 4 Core X 4 sq.mm.
*Use rated current when sizing control equipment."

f6="130 KG (Approx) without Cable"





l1="Casted Parts : Aluminum Alloy LM4"

l2="Outer Casing : Stainless Steel SS304"

l3="Motor Casing : Aluminum Alloy LM4"

l4="Motor Shaft : Stainless Steel SS431"

l5="Impeller : Stainless Steel SS410,"

l6="Hardened (HRC-55)"

l7="Fasteners : Stainless Steel  SS304"

l8="Oil Chamber : Aluminum Alloy  LM4"

l9="Diffuser : Nitrile Rubber coated"

l10="Strainer : Stainless Steel with circular holes of 20mm."






l11="Max. Submergence depth-15 Mtr."

l12="Max. Liquid Temperature-400C."


l13="**These pumps must be used with external motor protection in accordance with technical data."


/>




    </div>
  )
}

export default AK15350m