import React, { Component } from "react";
import classes from './Productscards.module.css';
import Products from './Products';



import img1 from './../../Assets/prop1.png';
import img2 from './../../Assets/prop2.png';
import img3 from './../../Assets/prop3.png';
import img4 from './../../Assets/prop4.png';
import img5 from './../../Assets/p5.png';
import img8 from './../../Assets/ni.jpg';

const Productscards=()=>{
return (
    <div className={classes.hold}>
        <h3 className={classes.head}>OUR <span className={classes.heads}>PRODUCTS</span></h3>
     
            <div className={classes.row}>

            <div>
                <Products img={img8} head='DEWATERING PUMPS'></Products>
            </div>
            <div>
                <Products img={img2} head='ELECTRIC SUBMERSIBLE DEWATERING PUMPS'></Products>
            </div>
            <div>
                <Products img={img4} head='NON-CLOG PUMPS'></Products>
            </div>
            <div>
                <Products img={img5} head='SLURRY PUMPS'></Products>
            </div>
            <div>
                <Products img={img8} head='SEWAGE PUPMS'></Products>
            </div>
            </div>

            <br></br>
    </div>
    
)
};

export default Productscards;