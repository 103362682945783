import React from 'react';
import classes from './Pro5.module.css';
import bbd from './../../Assets/prop2.png';



import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';



const Pro3 = () => {
  return (
    <div className={classes.Pro1}>

<div className={classes.Prw}>
        <div className={classes.l1}><Fade left>
   <img src={bbd} className={classes.imm}></img></Fade>
        </div>

        <Fade right>
        <div className={classes.r1}>

            <center>
        <h6 className={classes.pcc}>
            PRODUCT CATALOGUE
        </h6>
        <hr className={classes.hr}></hr>

 

        <h4  className={classes.head1}> SUBMERSIBLE<span className={classes.heads1}> DEWATERING </span>PUMPS
        </h4>
       


        <div className={classes.wrapper}>
      


<div className={classes.wra1}>
<h6 className={classes.hhd}>5 HP Submersible Dewatering Pump
    <br/>
(AK-5350-2P )</h6>
Electric Motor driven Submersible Dewatering Pump
<br/><br/>
        <table className={classes.keywords} cellspacing="5" cellpadding="0">
    <thead>
      <tr>
        <th>  INFORMATION</th>
        <th>FACILITIES</th>
      
    
      
      </tr>
    </thead>
    <tbody>
   
     
    <tr>
        <td className={classes.lalign}>No Of Stages : </td>
        <td>Single Stage  </td>
      
       

      </tr>
     

      <tr>
        <td className={classes.lalign}>Motor HP : </td>
        <td>5 HP  </td>
      
       

      </tr>
    
      <tr>
        <td className={classes.lalign}>Make : </td>
        <td>KPR's  </td>
        
       

      </tr>
      <tr>
        <td className={classes.lalign}>Pump Model : </td>
        <td>AK-5350-2P  </td>
       
        
   
      </tr>
  
      <tr>
        <td className={classes.lalign}>Max Head : </td>
        <td>22 Mtr  </td>
    
   
      </tr>
      <tr>
        <td className={classes.lalign}>Max Discharge : </td>
        <td>1200 LPM  </td>
      
      </tr>
      <tr>
        <td className={classes.lalign}>Solid Hnadling Capacity : </td>
        <td>Up to 10 mm  </td>
      
      </tr>
      <tr>
        <td className={classes.lalign}>Discharge Connection : </td>
        <td>3” Hose Type  </td>
       
      </tr>
  

    </tbody>
  </table>

  <hr/>
  Avaible in fully stainless steel 304 and 316 constructions.
Also available in both 50 Hz and 60 Hz
<br/><hr/><h6>Applications :</h6>
    
<p> Designed for commercial and Industrial applicatiobs where Seepage. Effluent and Storm water drainage is essential. Effectlvely drains Sumps, Basements and Construction Sites. </p>

</div>

<div className={classes.wra2}>

<h6 className={classes.hhd}>10 HP Submersible Dewatering Pump <br/>
(AK-10350-2P )</h6>
Electric Motor driven Submersible Dewatering Pump
<br/><br/>
        <table className={classes.keywords} cellspacing="5" cellpadding="0">
    <thead>
      <tr>
        <th>  INFORMATION</th>
        <th>FACILITIES</th>
      
    
      
      </tr>
    </thead>
    <tbody>
   
     
    <tr>
        <td className={classes.lalign}>No Of Stages : </td>
        <td>Single Stage  </td>
      
       

      </tr>
     

      <tr>
        <td className={classes.lalign}>Motor HP : </td>
        <td>10 HP  </td>
      
       

      </tr>
    
      <tr>
        <td className={classes.lalign}>Make : </td>
        <td>KPR's  </td>
        
       

      </tr>
      <tr>
        <td className={classes.lalign}>Pump Model : </td>
        <td>AK-10350-2P  </td>
       
        
   
      </tr>
  
      <tr>
        <td className={classes.lalign}>Max Head : </td>
        <td>30 Mtr  </td>
    
   
      </tr>
      <tr>
        <td className={classes.lalign}>Max Discharge : </td>
        <td>3200 LPM  </td>
      
      </tr>
      <tr>
        <td className={classes.lalign}>Solid Hnadling Capacity : </td>
        <td>Up to 10 mm  </td>
      
      </tr>
      <tr>
        <td className={classes.lalign}>Discharge Connection : </td>
        <td>4” Hose Type  </td>
       
      </tr>
  

    </tbody>
  </table>

<hr/>
  Avaible in fully stainless steel 304 and 316 constructions.
Also available in both 50 Hz and 60 Hz
<br/><hr/><h6>Applications :</h6>
<p> Designed for commercial and Industrial applicatiobs where Seepage. Effluent and Storm water drainage is essential. Effectlvely drains Sumps, Basements and Construction Sites. </p>


</div>



<div className={classes.wra3}>

<h6 className={classes.hhd}>35 HP Submersible Dewatering Pump <br/>
(AK-35350-2P )</h6>
Electric Motor driven Submersible Dewatering Pump
<br/><br/>
        <table className={classes.keywords} cellspacing="5" cellpadding="0">
    <thead>
      <tr>
        <th>  INFORMATION</th>
        <th>FACILITIES</th>
      
    
      
      </tr>
    </thead>
    <tbody>
   
     
    <tr>
        <td className={classes.lalign}>No Of Stages : </td>
        <td>Single Stage  </td>
      
       

      </tr>
     

      <tr>
        <td className={classes.lalign}>Motor HP : </td>
        <td>35 HP  </td>
      
       

      </tr>
    
      <tr>
        <td className={classes.lalign}>Make : </td>
        <td>KPR's  </td>
        
       

      </tr>
      <tr>
        <td className={classes.lalign}>Pump Model : </td>
        <td>AK-35350-2P  </td>
       
        
   
      </tr>
  
      <tr>
        <td className={classes.lalign}>Max Head : </td>
        <td>45 Mtr  </td>
    
   
      </tr>
      <tr>
        <td className={classes.lalign}>Max Discharge : </td>
        <td>7200 LPM  </td>
      
      </tr>
      <tr>
        <td className={classes.lalign}>Solid Hnadling Capacity : </td>
        <td>Up to 30 mm  </td>
      
      </tr>
      <tr>
        <td className={classes.lalign}>Discharge Connection : </td>
        <td>6” Hose Type  </td>
       
      </tr>
  

    </tbody>
  </table>
 
  <hr/>
  Avaible in fully stainless steel 304 and 316 constructions. 
Also available in both 50 Hz and 60 Hz
<br/><hr/><h6>Applications :</h6>
<p> The Pump comes in Robust Construction and best suitable for High discharg, availabl in both DOL and Star Delta connections.</p>



</div>




  
 
 </div> 
 </center>
        </div>
        </Fade>

</div>

    </div>
  )
}

export default Pro3