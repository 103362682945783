import React from 'react';
import classes from "./Wcy.module.css";
import { Link } from 'react-router-dom';
import a1 from "./../../Assets/aa1.png";
import a2 from "./../../Assets/aa2.png";
import a4 from "./../../Assets/aa4.png";
import a3 from "./../../Assets/aa3.png";

const Wcy = () => {
  return (
  <div className={classes.heddd}>



<div className={classes.left1}>

    <div className={classes.h1}>

<h1>
 <span className={classes.head}>  WHY </span> <br></br>
 <span className={classes.heads}> KPR'S PUMP </span><br></br>
</h1>

    </div>

<br></br>

    <div className={classes.p1}>

    Dewatering pump manufacturing involves designing pumps tailored to specific applications, optimizing for efficiency, and selecting durable materials.
    Precision manufacturing processes with rigorous quality control ensure reliability.

    </div>

    <br></br>

    <div>

      <button className={classes.b1}><Link to='/Contact'  className={classes.be}>CONTACT US</Link></button>
    
    </div>

</div>
   





<div className={classes.right1}>

<h5>
  <span className={classes.hd}>  What We Do...    </span> 
 </h5>
 <br></br>

<div className={classes.d}>
<img src={a1} className={classes.i1}/>

<div className={classes.d2}>
<h5  className={classes.h5}>
PUMP DESIGNING
</h5>
<p className={classes.p5}>
Consider factors like pump size, impeller design, and materials for durability and longevity.
</p>
</div>

</div>



<div className={classes.d}>
<img src={a2} className={classes.i1}/> 

<div className={classes.d2}>
<h5 className={classes.h5}>
EFFICIENCY AND PERFORMANCE
</h5>
<p className={classes.p5}>
Conduct performance testing to ensure pumps meet specified flow rates, head pressures, and other performance parameters.
</p>
</div>

</div>



<div className={classes.d}>
<img src={a3} className={classes.i1}/>

<div className={classes.d2}>
<h5 className={classes.h5}>
TESTING AND QUALITY
</h5>
<p className={classes.p5}>
Implement rigorous testing procedures to ensure pumps meet quality and performance standards.
</p>
</div>

</div>
   

   
<div className={classes.d}>
<img src={a4} className={classes.i1}/> 

<div className={classes.d2}>
<h5 className={classes.h5}>
MATERIAL SELECTION
</h5>
<p className={classes.p5}>
Choose high-quality materials for pump components to ensure resistance to corrosion, abrasion, and wear.
</p>
</div>

</div>
   
    </div>

    </div>
  
  )
}

export default Wcy