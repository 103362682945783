import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import RootLayout from './Pages/RootLayout';
import Home from './Pages/Home';
import About from './Pages/About';
import Facilities from './Pages/Facilities';
import Contact from './Pages/Contact';
import Enquiry from './Pages/Enquiry';
import Client from './Pages/Client';
import Product from './Pages/Product';
import Gallery from './Pages/Gallery';

import AK2350 from './Pages/AK2350';
import AK5150 from './Pages/AK5150';
import AK3350 from './Pages/AK3350';
import AK5350 from './Pages/AK5350';
import AK5360 from './Pages/AK5360';
import AK6350 from './Pages/AK6350';
import AK8350 from './Pages/AK8350';
import AK8350H from './Pages/AK8350H';
import AK10350 from './Pages/AK10350';
import AK15350 from './Pages/AK15350';
import AK35350 from './Pages/AK35350';
import AKs from './Pages/AKs';

import SK3350 from './Pages/SK3350';
import SK6350 from './Pages/SK6350';
import SK8350 from './Pages/SK8350';
import SK10350 from './Pages/SK10350';

const router = createBrowserRouter([{
  path: '/',
  element: <RootLayout></RootLayout>,

  children: [
   { path: '', element: <Home></Home>},
    { path: '/About', element: <About></About>},
    { path: '/Facilities', element: <Facilities></Facilities>},
    { path: '/Contact', element: <Contact></Contact>},
    { path: '/Enquiry', element: <Enquiry></Enquiry>},
    { path: '/Client', element: <Client></Client>},
    { path: '/Product', element: <Product></Product>},
    { path: '/Gallery', element: <Gallery></Gallery>},

    { path: '/AK5150', element: <AK5150></AK5150>},
    { path: '/AK2350', element: <AK2350></AK2350>},
    { path: '/AK3350', element: <AK3350></AK3350>},
    { path: '/AK5350', element: <AK5350></AK5350>},
    { path: '/AK5360', element: <AK5360></AK5360>},
    { path: '/AK6350', element: <AK6350></AK6350>},
    { path: '/AK8350', element: <AK8350></AK8350>},
    { path: '/AK8350H', element: <AK8350H></AK8350H>},
    { path: '/AK10350', element: <AK10350></AK10350>},
    { path: '/AK15350', element: <AK15350></AK15350>},
    { path: '/AK35350', element: <AK35350></AK35350>},
    { path: '/AKs', element: <AKs></AKs>},

    { path: '/SK3350', element: <SK3350></SK3350>},
    { path: '/SK6350', element: <SK6350></SK6350>},
    { path: '/SK8350', element: <SK8350></SK8350>},
    { path: '/SK10350', element: <SK10350></SK10350>},

  ]
}]);


const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App