import React from 'react';
import classes from './Ab3.module.css';

import Fade from 'react-reveal/Fade';

import img from "./../../Assets/ash.png";

const Ab3 = () => {
  return (
    <div>

<section className={classes.container}>
        <div className={classes.left}>
        <Fade top><br/>
          <h2 className={classes.heading}>OUR<span className={classes.heads}> MANAGEMENT</span></h2></Fade>
          <Fade left>
            <p className={classes.leftp}>&emsp;&emsp;&emsp; Mr. Ashwin R. Kolhe, a creative businessman born on October 2, 1973, started his career in 1996 in pumping industry after earning his degree in production engineering. He sought specialised training in the USA for next three years and 7 years in dubai. After having ten years of expertise with submersible dewatering pumps. Mr. Kolhe developed his own manufacturing facility in India after conducting a two-year market study in the GCC nations, where he provided pumps for renowned projects in Dubai and Abu Dhabi. KPR'S PUMPS was founded in July 2007 and has since flourished as a developing industry, with 60% of its products shipped to GCC nations and Saudi Arabia, while plans are being made for a breakthrough into the US market.
         
</p>   </Fade>
        </div>
        <div className={classes.right}>
          <Fade right>
            <img src={img} alt="KPR'S PUMPS" className={classes.ri}/>
            </Fade> </div>
    </section>
      
    </div>
  )
}

export default Ab3